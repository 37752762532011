<template>
  <div class="home">
    <div class="wrapper">
      <div class="top_select_date ds_flex ju_center al_center" @click="show = true">{{date}}</div>
      <div class="card">
        <div class="title ds_flex al_center">
          <div class="sub"></div>
          <div class="text">药康夫</div>
          <div class="btn" @click="ykfMoreData">更多数据</div>
        </div>
        <div class="card_box">
          <div class="text">新增VIP会员</div>
          <div class="nums"><span class="num">{{ykfDayData.vipMemberNum || 0}}</span>人</div>
          <div class="rate" v-if="ykfDayData.vipMemberRatio == '--'">
            <span class="txt">环比</span>
            <span class="num">{{ykfDayData.vipMemberRatio}}</span>
            <!-- <span class="icon icon_up"> -->
            <!-- icon_down --></span>
          </div>
          <div class="rate" v-else>
            <span class="txt">环比</span>
            <span class="num">{{ykfDayData.vipMemberRatio}}</span>
            <span class="icon" v-if="ykfDayData.vipMemberRatio" :class="{icon_down: ykfDayData.vipMemberRatio.indexOf('-') > -1, icon_up: ykfDayData.vipMemberRatio.indexOf('-') == -1}">
              <!-- icon_down --></span>
          </div>
        </div>
        <div class="total_box ds_flex al_center">
          <span class="txt">本月累计新增</span><span class="num">{{ykfMonthData.vipMemberNum}}人</span>
        </div>
      </div>
      <div class="card">
        <div class="title ds_flex al_center">
          <div class="sub"></div>
          <div class="text">O2O商城</div>
          <div class="btn" @click="otoData">更多数据</div>
        </div>
        <div class="card_box">
          <div class="text">成交额</div>
          <div class="nums"><span class="num">0</span>元</div>
          <div class="rate">
            <span class="txt">环比</span>
            <span class="num">--</span>
            <!-- <span class="icon "></span> -->
          </div>
        </div>
        <div class="total_box ds_flex al_center">
          <span class="txt">本月累计成交</span><span class="num">0元</span>
        </div>
      </div>
      <div class="card">
        <div class="title ds_flex al_center">
          <div class="sub"></div>
          <div class="text">电子处方</div>
          <div class="btn" @click="cdMoreData">更多数据</div>
        </div>
        <div class="card_box">
          <div class="text">开方单量</div>
          <div class="nums"><span class="num">{{prescriptionData.daylyPrescriptionCount || 0}}</span>单</div>
          <div class="rate" v-if="prescriptionData.daylyPrescriptionCountPrecent == '--'">
            <span class="txt">环比</span>
            <span class="num">{{prescriptionData.daylyPrescriptionCountPrecent}}</span>
            <!-- <span class="icon" v-if="prescriptionData.daylyPrescriptionCountPrecent" :class="{icon_down: (prescriptionData.daylyPrescriptionCountPrecent + '').indexOf('-') > -1, icon_up: (prescriptionData.daylyPrescriptionCountPrecent + '').indexOf('-') == -1}"> -->
            <!-- icon_down --></span>
          </div>
          <div class="rate" v-else>
            <span class="txt">环比</span>
            <span class="num">{{prescriptionData.daylyPrescriptionCountPrecent}}</span>
            <span class="icon" v-if="prescriptionData.daylyPrescriptionCountPrecent" :class="{icon_down: (prescriptionData.daylyPrescriptionCountPrecent + '').indexOf('-') > -1, icon_up: (prescriptionData.daylyPrescriptionCountPrecent + '').indexOf('-') == -1}">
              <!-- icon_down --></span>
          </div>
        </div>
        <div class="total_box ds_flex al_center">
          <span class="txt">本月累计单量</span><span class="num">{{prescriptionData.monthPrescriptionCount || 0}}单</span>
        </div>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom" :style="{ height: 'auto' }">
      <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" :min-date="minDate" :max-date="maxDate" @confirm="confirmDate" />
    </van-popup>
  </div>
</template>
<script>
import { getDate, getMonthStartAndEnd } from '@/utils/util';
import { Toast } from 'vant';
export default {
  components: {
  },
  data() {
    return {
      show: false,
      minDate: new Date(2019, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      date: null,
      ykfDayData: '', //药康夫
      ykfMonthData: '', //药康夫月统计
      prescriptionData: '', //电子处方
    }
  },
  /**
   * 统计数据接口统一访问药易加系统，原api文档上的url统一添加以下前缀访问：
/prescription/*   电子处方接口转发前缀
/yaokangfu/*   药康夫接口转发前缀
   */
  created() {
    this.date = getDate('-', '', false);
    console.log(getMonthStartAndEnd(0, this.date))
    this.ykfMemberDayData(this.date);
    this.ykfMemberMonthData(this.date);
    this.dzcfMemberData(this.date);
  },
  methods: {
    confirmDate(item) {
      console.log(item);
      this.date = getDate('-', item, false);
      this.ykfMemberDayData(this.date);
      this.ykfMemberMonthData(this.date);
      this.dzcfMemberData(this.date);
      this.show = false;
    },
    // 药康夫日统计
    ykfMemberDayData(date) {
      let params = {
        dayDate: date
      }
      if (this.userInfo.user.storeId) {
        params.storeId = this.userInfo.user.storeId;
      } else {
        params.enterpriseId = this.userInfo.user.enterpriseId;
      }
      this.$http('get', '/yaokangfu/system/memberDataStatistics/memberDayData', params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.ykfDayData = res.data;
        }
      })
    },
    // 药康夫月统计
    ykfMemberMonthData(date) {
      let params = {
        startDate: getMonthStartAndEnd(0, date)[0],
        endDate: getMonthStartAndEnd(0, date)[1],
        timeline: 'month'
      }
      if (this.userInfo.user.storeId) {
        params.storeId = this.userInfo.user.storeId;
      } else {
        params.enterpriseId = this.userInfo.user.enterpriseId;
      }
      this.$http('get', '/yaokangfu/system/memberDataStatistics/memberHistoryData', params).then(res => {
        console.log('月', res);
        this.ykfMonthData = res.data;
      })
    },
    // 电子处方日统计
    dzcfMemberData(date) {
      let params = {
        searchDate: date
      }
      if (this.userInfo.user.storeId) {
        params.storeId = this.userInfo.user.storeId;
      } else {
        params.enterpriseId = this.userInfo.user.enterpriseId; //药企id、
      }
      this.$http('get', '/prescription/statistics/getAPPDaylyHomeStatisticVO', params).then(res => {
        if (res.code == 200) {
          this.prescriptionData = res.data;
          if (!this.prescriptionData.daylyPrescriptionCountPrecent) {
            this.prescriptionData.daylyPrescriptionCountPrecent = '--';
          } else {
            this.prescriptionData.daylyPrescriptionCountPrecent = this.prescriptionData.daylyPrescriptionCountPrecent + '%';
          }
        }
      })
    },
    // 药康夫更多数据
    ykfMoreData() {
      this.$router.push({ path: '/history_data_ykf' })
    },
    // 电子处方更多数据
    cdMoreData() {
      this.$router.push({ path: '/history_data_cf' });
    },
    // 
    otoData() {
      Toast('oto商城系统正在接入中，请耐心等待')
    }
  }
}
</script>
<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkit-flex;
}
.ju_center {
  justify-content: center;
  -webkit-justify-content: center;
}
.al_center {
  align-items: center;
  -webkit-align-items: center;
}
.home {
  background: #f8f8f8;
  min-height: 100%;
}
.wrapper {
  padding: 0.8rem 0.3rem;
}
.top_select_date {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.6rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: #333;
  background: #fff;
}
.top_select_date::after {
  content: "";
  display: inline-block;
  margin-left: 0.29rem;
  width: 0.29rem;
  height: 0.14rem;
  background: url("../assets/img/btn_dropdown@2x.png") no-repeat center / 100%
    100%;
}
.card {
  margin-bottom: 0.15rem;
  padding: 0.3rem 0 0.23rem;
  background: #fff;
  border-radius: 8px;
  .title {
    margin-bottom: 0.27rem;
    .sub {
      margin-right: 0.25rem;
      width: 4px;
      height: 0.32rem;
      background: #3780ff;
      border-radius: 0px 2px 2px 0px;
    }
    .text {
      flex: 1;
      -webkit-flex: 1;
      font-size: 0.32rem;
      font-weight: bold;
      color: #333;
    }
    .btn {
      margin-right: 0.23rem;
      font-size: 0.28rem;
      color: #3780ff;
    }
    .btn::after {
      content: "";
      display: inline-block;
      margin-left: 0.1rem;
      width: 0.13rem;
      height: 0.24rem;
      background: url("../assets/img/btn_enter@2x.png") no-repeat center / 100%
        100%;
    }
  }
  .card_box {
    margin: 0 0.29rem;
    padding-bottom: 0.23rem;
    border-bottom: 1px solid #eeeeee;
    .text {
      margin-bottom: 0.24rem;
      font-size: 0.24rem;
      color: #666;
    }
    .nums {
      margin-bottom: 0.21rem;
      font-size: 0.3rem;
      color: #333;
      .num {
        font-size: 0.48rem;
        line-height: 0.48rem;
        font-weight: bold;
        color: #00c7c0;
      }
    }
    .rate {
      font-size: 0.26rem;
      .txt {
        margin-right: 0.3rem;
        color: #666;
      }
      .num {
        color: #333;
      }
      .icon {
        display: inline-block;
        margin-left: 0.1rem;
        width: 0.16rem;
        height: 0.23rem;
      }
      .icon_up::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        background: url("../assets/img/icon_rise_nor@2x.png") no-repeat center /
          100% 100%;
      }
      .icon_down::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        background: url("../assets/img/icon_reduce_nor@2x.png") no-repeat center /
          100% 100%;
      }
    }
  }
  .total_box {
    margin: 0 0.29rem;
    padding-top: 0.24rem;
    font-size: 0.28rem;
    color: #333;
    .txt {
      margin-right: 0.3rem;
    }
  }
}
</style>
